<template>
  <div/>
</template>

<script>
// Утилиты
import {prepareSinRequest, prepareSinResponse} from '@/utils/http';

// Миксины
import BaseAction from '@/components/actions/BaseAction';

export default {
  name: 'OpenRouteEditor',
  mixins: [
    BaseAction,
  ],
  mounted() {
    if (!this.selected) {
      return;
    }

    this.loadData();
  },
  methods: {
    async loadData() {
      const routesRegisterId = this.selected.routesregisterId;
      const dateBegin = this.$store.getters['period/begin'];
      const dateEnd = this.$store.getters['period/end'];
      const startDtMillis = dateBegin.getTime();
      const endDtMillis = dateEnd.getTime();

      let query = 'sin2:/v:8f2e552f-1c34-4a70-b702-c729716ac96f/';

      query += `?filter=and(eq(field(".servicedRouteID"),param("${routesRegisterId}", "id"))`;
      query += `,not(eq(field(".stateID"), param("c2cd5446-7849-4ab4-89f8-bcef841fd2a0", "id")))`;
      query += `,gte(field(".endDt"), var("util.date.dateFromMillis(${startDtMillis}l)", "date"))`;
      query += `,lte(field(".startDt"), var("util.date.dateFromMillis(${endDtMillis}l)", "date")))`;

      const result = await prepareSinResponse(
        await prepareSinRequest(
          'core-read',
          query,
          '',
          ['file', 'ID', 'tmpFile', 'cardSeries', 'cardNumber', 'isfile']
        ),
        {
          hideUnderscores: true,
        }
      );

      const defMsg = 'Документ не добавлен в систему для карты';
      let msg = defMsg;
      let delimiter = '';

      if (result !== null && Array.isArray(result) && result.length > 0) {
        for (const row of result) {
          if (row.isfile) {
            location.href = '/rpc/?d=file&uri=fs:id:' + row.tmpfile;
          } else {
            msg += `${delimiter} ${row.contractdata}`;
            delimiter = ',';
          }
        }

        if (msg !== defMsg) {
          msg += '.\nДля его добавления перейдите в раздел «2 Пассажирские перевозки – 2.10 Реестры - 2.10.1 Реестр карт».';

          this.showMessage('warning', msg);
        }
      } else {
        this.showMessage(
          'warning',
          'На рабочий период нет действующей карты маршрута.\nДля ее добавления перейдите в раздел «2 Пассажирские перевозки – 2.10 Реестры - 2.10.1 Реестр карт».'
        );
      }
    }
  },
}
</script>
